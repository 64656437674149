import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getHourlyWages() {
    return axiosIns.get('/admin/hourly_wages')
  },
  createHourlyWage({ amount, timerangeStartAt, timerangeEndAt }) {
    const formData = serialize(snakecaseKeys({
      hourlyWage: { amount, timerangeStartAt, timerangeEndAt },
    }))

    return axiosIns.post('/admin/hourly_wages', formData)
  },
  updateHourlyWage({
    id,
    amount,
    timerangeStartAt,
    timerangeEndAt,
    userHourlyWagesAttributes,
  }) {
    const formData = serialize(snakecaseKeys({
      hourlyWage: {
        id,
        amount,
        timerangeStartAt,
        timerangeEndAt,
        userHourlyWagesAttributes,
      },
    }))

    return axiosIns.put(`/admin/hourly_wages/${id}`, formData)
  },
  deleteHourlyWage(id) {
    return axiosIns.delete(`/admin/hourly_wages/${id}`)
  },
}
