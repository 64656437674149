<template>
  <div>
    <v-skeleton-loader
      v-if="isLoading"
      type="table"
    />

    <div v-else>
      <div class="pa-0 px-1 pb-3 w-full d-flex align-center justify-end">
        <v-dialog
          v-model="isCreating"
          width="500"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              :ripple="false"
              fab
              small
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              新規時給
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model.number="addTarget.amount"
                    type="number"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    :error="!isValidAddTarget.amount"
                    label="時給金額"
                    min="1"
                    prefix="¥"
                  />
                </v-col>
              </v-row>

              <v-row class="my-6">
                <v-col cols="12">
                  <yona-time-picker
                    :value="addTarget.timerangeStartAt"
                    label="対象時間帯 開始"
                    :error="!isValidAddTarget.timerangeStartAt"
                    @update:value="addTarget.timerangeStartAt = $event"
                  />
                </v-col>
              </v-row>

              <v-row class="my-6">
                <v-slide-x-transition>
                  <v-col
                    v-show="addTarget.timerangeEndAt !== null"
                    cols="8"
                  >
                    <yona-time-picker
                      v-if="addTarget.timerangeEndAt"
                      :value="addTarget.timerangeEndAt"
                      label="対象時間帯 終了"
                      :error="!isValidAddTarget.timerangeEndAt"
                      @update:value="addTarget.timerangeEndAt = $event"
                    />
                  </v-col>
                </v-slide-x-transition>

                <v-col cols="4">
                  <v-checkbox
                    :value="addTarget.timerangeEndAt === null"
                    :ripple="false"
                    label="~ 24:00"
                    class="pa-0 ma-0"
                    @change="addTarget.timerangeEndAt = addTarget.timerangeEndAt === null ? '23:59' : null"
                  />
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :ripple="false"
                color="primary"
                :disabled="!(isValidAddTarget.amount && isValidAddTarget.timerangeStartAt && isValidAddTarget.timerangeEndAt)"
                @click="create"
              >
                作成
              </v-btn>
              <v-btn
                :ripple="false"
                color="blue darken-1"
                text
                @click="cancelCreate"
              >
                キャンセル
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>

      <v-data-table
        ref="dataTable"
        :key="`data-table-${hourlyWages.length}`"
        :headers="headers"
        :items="hourlyWages"
        :header-props="{ sortByText: 'ソート' }"
        hide-default-footer
        disable-pagination
        :expanded="hourlyWages"
        show-group-by
        sort-by="attributes.amount"
        no-data-text="データがありません"
      >
        <template #[`group.header`]="{ groupBy, group, remove }">
          <td
            :colspan="headers.length"
            @click="remove"
          >
            <span class="mr-4">{{ headers.find(obj => obj.value === groupBy[0]).text }}</span>
            <strong v-if="groupBy[0] === 'attributes.amount'">¥{{ group.toLocaleString() }}</strong>
            <strong v-else>{{ hhmm(group) }}</strong>
          </td>
        </template>

        <template #[`item.attributes.amount`]="{ item }">
          <yona-edit-dialog
            :display-name="`¥${item.attributes.amount.toLocaleString()}`"
            :is-valid="isValidEditTarget.amount"
            @open="edit({ id: item.id, amount: item.attributes.amount })"
            @save="update(item.id)"
          >
            <template #input>
              <v-text-field
                v-if="isEditing"
                v-model.number="editTarget.amount"
                type="number"
                inputmode="numeric"
                pattern="[0-9]*"
                :error="!isValidEditTarget.amount"
                min="1"
                prefix="¥"
              />
            </template>
          </yona-edit-dialog>
        </template>

        <template #[`item.attributes.timerangeStartAt`]="{ item }">
          {{ hhmm(item.attributes.timerangeStartAt) }}
        </template>

        <template #[`item.attributes.timerangeEndAt`]="{ item }">
          {{ item.attributes.timerangeEndAt ? hhmm(item.attributes.timerangeEndAt) : '24:00' }}
        </template>

        <template #[`item.actions`]="{ item }">
          <yona-edit-dialog
            title="削除の確認"
            btn-color="error"
            save-text="同意の上で削除"
            @save="destroy(item.id)"
          >
            <template #append-outer-display-name>
              <v-btn
                icon
                :ripple="false"
              >
                <v-icon small>
                  {{ icons.mdiTrashCanOutline }}
                </v-icon>
              </v-btn>
            </template>

            <template #input>
              <span>
                削除を実行してもよろしいですか
              </span>
            </template>
          </yona-edit-dialog>
        </template>

        <template #expanded-item="{ item }">
          <td :colspan="headers.length">
            <v-row>
              <v-col class="ps-4">
                <p>
                  対象ユーザー
                  <span>({{ item.attributes.userHourlyWages.data.length }})</span>
                </p>

                <yona-edit-dialog
                  :is-valid="isValidEditTarget.userHourlyWagesAttributes"
                  persistent
                  @open="edit({
                    id: item.id,
                    userHourlyWagesAttributes: [...editTargetUserHourlyWagesAttributes[getUserHourlyWageIndex(item.id)]]
                  })"
                  @save="update(item.id)"
                >
                  <template #append-outer-display-name>
                    <div class="user-hourly-wages-chip-group">
                      <v-chip-group
                        v-if="item.attributes.userHourlyWages.data && item.attributes.userHourlyWages.data.length > 0"
                        column
                      >
                        <v-chip
                          v-for="user in hourlyWageUsers(item)"
                          :key="`userHourlyWage-${item.id}-${user.id}`"
                        >
                          <v-avatar
                            v-if="('profileImage') in user.attributes"
                            left
                          >
                            <v-img
                              v-if="user.attributes.profileImage"
                              :src="user.attributes.profileImage"
                            />
                            <v-icon v-else>
                              {{ icons.mdiAccountCircleOutline }}
                            </v-icon>
                          </v-avatar>
                          {{ user.attributes.name }}
                        </v-chip>
                      </v-chip-group>

                      <span v-else>なし</span>
                    </div>
                  </template>

                  <template #input>
                    <yona-has-many-v-select
                      :key="`hourlyWage-${item.id}-${editTarget.id}-userHourlyWagesAttributes`"
                      v-model="editTarget.userHourlyWagesAttributes"
                      parent-name="hourlyWage"
                      :parent-id="item.id"
                      :children="userItems"
                      child-name="user"
                      :error="!isValidEditTarget.userHourlyWagesAttributes"
                      :error-messages="isValidEditTarget.userHourlyWagesAttributes ? [] : ['他の時給の対象時間帯と重複しているユーザーがいます']"
                    />
                  </template>
                </yona-edit-dialog>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  computed,
  getCurrentInstance,
} from '@vue/composition-api'
import {
  mdiTrashCanOutline,
  mdiPlus,
  mdiCheckCircleOutline,
  mdiCloseCircleOutline,
  mdiAccountCircleOutline,
} from '@mdi/js'
import {
  get,
  each,
  map,
  groupBy,
  keys,
  intersection,
  isEmpty,
  find,
  findIndex,
  flatMap,
} from 'lodash'
import HourlyWageApi from '@/api/admin/HourlyWage'
import UserApi from '@/api/waiter/User'
import useDataTableEdit from '@/views/composable/useDataTableEdit'
import useDateFormat from '@/views/composable/useDateFormat'
import useCompInit from '@/views/composable/useCompInit'
import YonaEditDialog from '@/views/components/util/YonaEditDialog.vue'
import YonaTimePicker from '@/views/components/util/YonaTimePicker.vue'
import YonaHasManyVSelect from '@/views/components/util/YonaHasManyVSelect.vue'

export default {
  components: {
    YonaEditDialog,
    YonaTimePicker,
    YonaHasManyVSelect,
  },
  setup() {
    const vm = getCurrentInstance().proxy

    const isCreating = ref(false)
    const hourlyWages = ref([])
    const users = ref([])
    const editTargetUserHourlyWagesAttributes = ref([])
    const addTarget = reactive({ amount: 1000, timerangeStartAt: '00:00', timerangeEndAt: '23:59' })

    const {
      isEditing,
      editTarget,
      cancelEdit,
      edit,
      isEditingHere,
    } = useDataTableEdit()
    const { hhmm } = useDateFormat()
    const { isLoading, initWith } = useCompInit()

    const userItems = computed(() => {
      const roleMsg = {
        cast: 'キャスト',
        waiter: 'ボーイ',
      }
      const lists = []
      const usersGrouped = groupBy(users.value, 'attributes.role')

      each(keys(roleMsg), role => {
        if (!usersGrouped[role]) return

        lists.push({ header: roleMsg[role] })
        lists.push(...usersGrouped[role])
      })

      return lists
    })

    const getUserHourlyWageIndex = id => {
      return hourlyWages.value.map(hourlyWage => hourlyWage.id).indexOf(id)
    }

    const isValidUserHourlyWages = (hourlyWage, userHourlyWagesAttributes) => {
      if (!(hourlyWage && userHourlyWagesAttributes)) return false

      // NOTE: 現在選択中の時給の対象時間範囲
      const startAt1 = new Date(hourlyWage.attributes.timerangeStartAt)
      let endAt1 = hourlyWage.attributes.timerangeEndAt
      endAt1 = endAt1 ? new Date(endAt1) : Number.POSITIVE_INFINITY

      // NOTE: 現在選択中の時給に紐づけようとする/紐づけられているユーザー
      const currentUserIds = map(userHourlyWagesAttributes, userHourlyWage => +userHourlyWage.userId)

      const hasOverlapTimerangeUser = find(hourlyWages.value, o => {
        if (+o.id === +hourlyWage.id) return false

        const overlapTargetUserIds = intersection(
          currentUserIds,
          map(o.attributes.userHourlyWages.data, data => +data.attributes.userId),
        )
        if (isEmpty(overlapTargetUserIds)) return false

        const startAt2 = new Date(o.attributes.timerangeStartAt)
        let endAt2 = o.attributes.timerangeEndAt
        endAt2 = endAt2 ? new Date(endAt2) : Number.POSITIVE_INFINITY

        return ((startAt1 < endAt2) && (startAt2 < endAt1))
      })

      return !hasOverlapTimerangeUser
    }

    const arrangeUserHourlyWageAttributes = () => {
      editTargetUserHourlyWagesAttributes.value = []

      each(hourlyWages.value, hourlyWage => {
        const userHourlyWages = map(hourlyWage.attributes.userHourlyWages.data, userHourlyWage => {
          return { id: userHourlyWage.id, ...userHourlyWage.attributes }
        })

        editTargetUserHourlyWagesAttributes.value.push(userHourlyWages)
      })
    }

    const hourlyWageUsers = item => {
      return flatMap(item.attributes.userHourlyWages.data, userHourlyWage => {
        return find(users.value, user => +user.id === +userHourlyWage.attributes?.userId) || []
      })
    }

    const cancelCreate = () => {
      isCreating.value = false
      addTarget.amount = 1000
      addTarget.timerangeStartAt = '00:00'
      addTarget.timerangeEndAt = '23:59'
    }

    const create = async () => {
      const res = await HourlyWageApi.createHourlyWage(addTarget)

      if (res?.data?.data) {
        hourlyWages.value.push(res.data.data)
        arrangeUserHourlyWageAttributes()
        vm.$toast.success('時給を作成しました')
      }

      cancelCreate()
    }

    const update = async id => {
      const {
        amount,
        timerangeStartAt,
        timerangeEndAt,
        userHourlyWagesAttributes,
      } = editTarget.value

      const res = await HourlyWageApi.updateHourlyWage({
        id,
        amount,
        timerangeStartAt,
        timerangeEndAt,
        userHourlyWagesAttributes,
      })

      if (res?.data?.data) {
        const index = findIndex(hourlyWages.value, hourlyWage => +hourlyWage.id === +id)
        hourlyWages.value.splice(index, 1, res.data.data)
        arrangeUserHourlyWageAttributes()
        vm.$toast.success('時給を更新しました')
      }

      cancelEdit()
    }

    const destroy = async id => {
      const res = await HourlyWageApi.deleteHourlyWage(id)
      // findINdex

      if (res) {
        const index = findIndex(hourlyWages.value, hourlyWage => +hourlyWage.id === +id)
        hourlyWages.value.splice(index, 1)
        vm.$toast.success('時給を削除しました')
      }
    }

    const getHourlyWages = async () => {
      const res = await HourlyWageApi.getHourlyWages()
      if (res?.data) {
        hourlyWages.value = [...res.data.hourlyWages.data]
        arrangeUserHourlyWageAttributes()
      }
    }

    const getUsers = async () => {
      const res = await UserApi.getUsers(['cast', 'waiter'])
      if (res?.data) users.value = [...res.data.users.data]
    }

    const isValidAddTarget = computed(() => {
      const isValid = {
        amount: addTarget.amount && addTarget.amount > 0,
        timerangeStartAt: true,
        timerangeEndAt: true,
      }

      return isValid
    })

    const isValidEditTarget = computed(() => {
      const isValid = {
        amount: true,
        userHourlyWagesAttributes: true,
      }
      const hourlyWage = find(hourlyWages.value, d => +d.id === +editTarget.value.id)
      if (!hourlyWage) return isValid

      isValid.amount = editTarget.value.amount && editTarget.value.amount > 0
      isValid.userHourlyWagesAttributes = isValidUserHourlyWages(hourlyWage, editTarget.value.userHourlyWagesAttributes)

      return isValid
    })

    initWith([
      getHourlyWages(),
      getUsers(),
    ])

    return {
      // data
      isLoading,
      isCreating,
      isEditing,
      hourlyWages,
      users,
      headers: [
        { text: '時給金額', value: 'attributes.amount' },
        { text: '対象時間帯 開始', value: 'attributes.timerangeStartAt' },
        { text: '対象時間帯 終了', value: 'attributes.timerangeEndAt' },
        {
          text: '',
          value: 'actions',
          align: 'end',
          sortable: false,
          groupable: false,
        },
      ],
      addTarget,
      editTarget,
      editTargetUserHourlyWagesAttributes,
      isValidAddTarget,
      isValidEditTarget,

      // computed
      userItems,

      // methods
      hhmm,
      getUserHourlyWageIndex,
      isValidUserHourlyWages,
      hourlyWageUsers,
      create,
      edit,
      isEditingHere,
      cancelEdit,
      cancelCreate,
      update,
      destroy,

      icons: {
        mdiTrashCanOutline,
        mdiPlus,
        mdiCheckCircleOutline,
        mdiCloseCircleOutline,
        mdiAccountCircleOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/preset/preset/mixins.scss';

@include theme--child(v-data-table__expanded__content) using ($material) {
  td {
    background-color: map-deep-get($material, 'background');
  }

  .user-hourly-wages-chip-group {
    border-bottom: thin solid rgba(map-deep-get($material, 'primary-shade'), 0.14) !important;
  }
}

.v-data-table__expanded__content td {
  width: 100vw;
  vertical-align: top;

  &>div:first-child {
    padding: 12px 0 32px 0;

    &>.col {
      background-color: rgba(0,0,0,.05);
    }
  }
}
</style>
