<template>
  <div>
    <time-picker
      :value="time"
      :dialog="true"
      :hide-button="true"
      class="secondary--text text-xl cursor-pointer"
      :class="rootThemeClass"
      @input="time = $event"
    >
      <template #activator="{ on }">
        <v-text-field
          :value="value"
          :label="label"
          :prepend-icon="prependIcon"
          :append-icon="appendIcon"
          :append-outer-icon="appendOuterIcon"
          readonly
          hide-details
          :disabled="disabled"
          :error="error"
          v-on="on"
        />
      </template>
    </time-picker>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { TimePicker } from 'vue-drumroll-datetime-picker'
import { useVModel } from '@vueuse/core'
import { getVuetify } from '@/@core/utils'
import 'vue-drumroll-datetime-picker/dist/style.css'

export default {
  components: {
    TimePicker,
  },
  props: {
    value: {
      type: String,
      required: true,
      default: '00:00',
    },
    label: {
      type: String,
      default: '',
    },
    prependIcon: {
      type: String,
      default: '',
    },
    appendIcon: {
      type: String,
      default: '',
    },
    appendOuterIcon: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const time = useVModel(props, 'value')

    const $vuetify = getVuetify()

    const rootThemeClass = computed(() => {
      return $vuetify.theme.isDark ? 'theme--dark' : 'theme--light'
    })

    return {
      time,

      // computed
      rootThemeClass,
    }
  },
}
</script>

<style lang="scss" scoped>
.v-input {
  margin: 0;
  padding: 0;
}
</style>
